import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AccountNotificationSubscription} from "@/enum/AccountNotificationSubscription.enum";

/**
 * deals with all the requests that have to do with accounts
 */
export default class AccountNotificationSubscriptionRepository {
    /**
     * subscribes to a notification channel
     */
    public static getSubscriptions(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`api/notification-subscription`);
    }

    /**
     * subscribes to a notification channel
     */
    public static subscribeTo(type: AccountNotificationSubscription): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/notification-subscription/${type}`);
    }

    /**
     * unsubscribes to a notification channel
     * @param type
     */
    public static unsubscribeTo(type: AccountNotificationSubscription): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`api/notification-subscription/${type}`);
    }
}
