
import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import AccountNotificationSubscriptionRepository from "@/api/repositories/AccountNotificationSubscriptionRepository";
import {AccountNotificationSubscription} from "@/enum/AccountNotificationSubscription.enum";

@Component
export default class ChangeNotificationComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * stores all the subscribed notifications of the user
   */
  private subscribedNotifications! : AccountNotificationSubscription[];

  /**
   * flag that indicates if an error occurred while loading the subscriptions
   */
  private hasErrorLoadingStatus = false;

  /**
   * loading flag, used for updating the state (e.g. on subscribe)
   * @private
   */
  private isLoading = false;

  /**
   * loading flag, used for loading the status on init / creating the view
   */
  private loadingStatus = false;

  /**
   * value of the checkbox, stores the value
   */
  private subscribedNewsletter!: boolean;

  /**
   * flag that indicates if the user has subscribed the newsletter
   */
  private get hasSubscribedToNewsletter() {
    if(!this.subscribedNotifications) return false;
    return this.subscribedNotifications.includes(AccountNotificationSubscription.NEWSLETTER);
  }

  /**
   * fetches the status of the view
   */
  private async fetchSettings() {
    try {
      this.loadingStatus = true;
      const {data} = await AccountNotificationSubscriptionRepository.getSubscriptions();
      this.subscribedNotifications = data;
      this.subscribedNewsletter = this.hasSubscribedToNewsletter;
      this.hasErrorLoadingStatus = false;
    } catch(_) {
      this.hasErrorLoadingStatus = true;
    } finally {
      this.loadingStatus = false;
    }
  }

  /**
   * copies the stored restaurenteur of the auth store into a local copy
   */
  private async created() {
    await this.fetchSettings();
  }

  /**
   * is called when the user clicks on the save button, updates the
   * subscription state
   * @private
   */
  private async saveData() {
    try {
      // tries to change the password in the api
      this.isLoading = true;

      // unsubscribes the newsletter
      if(!this.subscribedNewsletter) {
        await AccountNotificationSubscriptionRepository.unsubscribeTo(AccountNotificationSubscription.NEWSLETTER);
      }

      // subscribes the newsletter
      if(this.subscribedNewsletter) {
        await AccountNotificationSubscriptionRepository.subscribeTo(AccountNotificationSubscription.NEWSLETTER);
      }
      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_NOTIFICATIONS_SUCCESS');
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_NOTIFICATIONS_ERROR');
    } finally {
      this.isLoading = false;
    }
  }
}
